/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * This is the Component which will get input from the user
 * and pass it as props to VegaReaderContainer.
 *
 * @file DetailsForm.js
 * @author Srinivasan Bakthavatchalam
 * @since 2.0.0
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';

import appConfig from '../config/appConfig';
import { BOOK_DATA_ARRAY } from '../common/constants';
import DetailsFormUtils from '../utils/DetailsFormUtils';

const styles = (theme) => ({
  root: {
    paddingRight: '10%',
    paddingLeft: '10%',
    paddingTop: '22px',
    backgroundColor: '#003058',
    overflow: 'scroll',
    height: '100vh'
  },
  margin: {
    margin: theme.spacing(1)
  },
  Paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(5)
  },
  details: {
    flexDirection: 'column'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold'
  },
  table: {
    minWidth: 650
  }
});

const textFieldStyle = {
  width: '48%',
  margin: 5,
  fontSize: 'small'
};

class DetailsForm extends Component {
  constructor(props) {
    super(props);

    this.booksData = this.handleGetBooks(BOOK_DATA_ARRAY) ? JSON.parse(this.handleGetBooks(BOOK_DATA_ARRAY)) : [];
    this.state = this.handleGetBooks(BOOK_DATA_ARRAY) ? this.handleState() : appConfig.updated;
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handleChangeToggle = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  /**
   * Handles the details in the last 5 saved Book Details table
   *
   * @param {Object} book
   */
  handleDetails = (book) => {
    let booksData = this.booksData;
    let key = Object.keys(book)[0];
    let obj = Object.values(book)[0];
    let keyIndex = booksData.findIndex(state => key === Object.keys(state)[0]);  // finds the key index in the local storage

    this.setState({ ...obj });
    booksData.splice(keyIndex, 1);
    booksData.push(book);
    this.handleSetBooks(BOOK_DATA_ARRAY,booksData);  // updates the book details in the localStorage array
  };

  /**
   * Handles the book data 
   * By getting stored book details
   * From Local Storage
   * 
   */
   handleGetBooks = (bookDataArray) => {
    const details = new DetailsFormUtils();
    const { getBooksFromLocalStorage } = details;
    const stateHandler = getBooksFromLocalStorage(bookDataArray);

    return stateHandler;
  };

  /**
   * Handles the book data 
   * By clearing stored book details
   * In Local Storage
   * 
   */
  handleRemoveBooks = (bookDataArray) => {
    const details = new DetailsFormUtils();
    const { removeBooksFromLocalStorage } = details;
    const stateHandler = removeBooksFromLocalStorage(bookDataArray);

    return stateHandler;
  };

  /**
   * Resets the local storage and form
   *
   */
  handleReset = () => {
    this.setState({
      ...appConfig.initial
    });
    this.handleRemoveBooks(BOOK_DATA_ARRAY);  // deletes the stored book details data in localStorage
  };

  /**
   * Handles the current submitting book details with the localStorage
   * @param {Object} state
   * 
   */
  handleSaveState = (state) => {
    let {bookId, courseId, contentType} = state;
    let key = `${bookId}${courseId}${contentType}`
    let obj = {};
    let booksData = this.booksData;

    obj[key] = state;
    if (!this.handleGetBooks(BOOK_DATA_ARRAY)) {
      let booksData = [];

      booksData.push(obj);
      this.handleSetBooks(BOOK_DATA_ARRAY,booksData);
    } else {
      let keyExists = booksData.filter(state => key === Object.keys(state)[0]);  // returns the current key and previous value of stored the book data if current key matches
      let keyIndex = booksData.findIndex(state => key === Object.keys(state)[0]);  // returns the current key index of the previous book data if current key matches

      if (keyExists.length) {
        if (keyIndex > -1) {
          booksData.splice(keyIndex, 1);  // deletes existing same book data from the list
        }
      } else {
        if (booksData.length === 5) {
          booksData.shift();
        }
      }
      booksData.push(obj);
      this.handleSetBooks(BOOK_DATA_ARRAY,booksData);
    }
  };

  /**
   * Handles the book data 
   * By clearing stored book details
   * In Local Storage
   * 
   */
   handleSetBooks = (bookDataArray,booksData) => {
    const details = new DetailsFormUtils();
    const { setBooksIntoLocalStorage } = details;
    const stateHandler = setBooksIntoLocalStorage(bookDataArray,booksData);

    return stateHandler;
  };

  /**
   * Handles the state data 
   * By getting last submitted book details
   * 
   */
  handleState = () => {
    const details = new DetailsFormUtils();
    const { getLatestStateData } = details;
    const stateHandler = getLatestStateData();
    return stateHandler;
  };

  /**
   * Handles the saving book details and opening the book
   * 
   */
  handleSubmit = () => {
    this.props.setDetails(this.state);
    this.handleSaveState(this.state);
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Paper className={classes.Paper} elevation={1}>
          {this.handleGetBooks(BOOK_DATA_ARRAY) && <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Last 5 Stored Book Details</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <div>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell> Key</TableCell>
                        <TableCell> Book Id</TableCell>
                        <TableCell> Course Id</TableCell>
                        <TableCell> Content Type</TableCell>
                        <TableCell> Details Link </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.booksData.map((book) => (
                        <TableRow key={Object.keys(book)[0]}>
                          <TableCell>{Object.keys(book)[0]}</TableCell>
                          <TableCell>{Object.values(book)[0].bookId}</TableCell>
                          <TableCell>{Object.values(book)[0].courseId}</TableCell>
                          <TableCell>{Object.values(book)[0].contentType}</TableCell>
                          <TableCell>
                            <Button
                              variant="outlined"
                              size="small"
                              color="primary"
                              onClick={() => { this.handleDetails(book) }}
                              className={classes.margin}
                            >
                              Details
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </AccordionDetails>
          </Accordion>}
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>AuthHandler and User Details</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <div>
                <TextField
                  id="standardToken"
                  label="Token"
                  value={this.state.token}
                  style={textFieldStyle}
                  onChange={this.handleChange('token')}
                  margin="normal"
                />
                <TextField
                  id="standardUserId"
                  label="User ID"
                  className={classes.textField}
                  value={this.state.userId}
                  style={textFieldStyle}
                  onChange={this.handleChange('userId')}
                  margin="normal"
                />
              </div>
              <div>
                <TextField
                  id="standardType"
                  label="Token Type"
                  className={classes.textField}
                  value={this.state.type}
                  style={textFieldStyle}
                  onChange={this.handleChange('type')}
                  margin="normal"
                />
                <TextField
                  id="standardUserRole"
                  label="User Role"
                  className={classes.textField}
                  value={this.state.userRole}
                  style={textFieldStyle}
                  onChange={this.handleChange('userRole')}
                  margin="normal"
                />
              </div>
              <div>
                <TextField
                  id="forgeRockToken"
                  label="ForgeRock Token"
                  className={classes.textField}
                  value={this.state.frToken}
                  style={textFieldStyle}
                  onChange={this.handleChange('frToken')}
                  margin="normal"
                />
              </div>
              <div>
                <TextField
                  id="hawkId"
                  label="Hawk Id"
                  className={classes.textField}
                  value={this.state.hawkId}
                  style={textFieldStyle}
                  onChange={this.handleChange('hawkId')}
                  margin="normal"
                />
                <TextField
                  id="hawkKey"
                  label="Hawk Key"
                  className={classes.textField}
                  value={this.state.hawkKey}
                  style={textFieldStyle}
                  onChange={this.handleChange('hawkKey')}
                  margin="normal"
                />
              </div>
              <div>
                <TextField
                  id="standardTenantId"
                  label="Tenant Id"
                  className={classes.textField}
                  value={this.state.tenantId}
                  style={textFieldStyle}
                  onChange={this.handleChange('tenantId')}
                  margin="normal"
                />
                <TextField
                  id="standardTenantKey"
                  label="Tenant Key"
                  className={classes.textField}
                  value={this.state.tenantKey}
                  style={textFieldStyle}
                  onChange={this.handleChange('tenantKey')}
                  margin="normal"
                />
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Book Details</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <div>
                <TextField
                  id="standardBasePath"
                  label="Base path"
                  className={classes.textField}
                  value={this.state.basePath}
                  style={textFieldStyle}
                  onChange={this.handleChange('basePath')}
                  margin="normal"
                />
                <TextField
                  id="standardBookId"
                  label="Book ID"
                  className={classes.textField}
                  value={this.state.bookId}
                  style={textFieldStyle}
                  onChange={this.handleChange('bookId')}
                  margin="normal"
                />
                <TextField
                  id="standardProductId"
                  label="Product ID"
                  className={classes.textField}
                  value={this.state.secondaryBookId}
                  style={textFieldStyle}
                  onChange={this.handleChange('secondaryBookId')}
                  margin="normal"
                />
                <TextField
                  id="standardBookId"
                  label="Old Book ID"
                  className={classes.textField}
                  value={this.state.legacyBookId}
                  style={textFieldStyle}
                  onChange={this.handleChange('legacyBookId')}
                  margin="normal"
                />
              </div>
              <div>
                <TextField
                  id="standardCourseId"
                  label="Course ID"
                  className={classes.textField}
                  value={this.state.courseId}
                  style={textFieldStyle}
                  onChange={this.handleChange('courseId')}
                  margin="normal"
                />
                <TextField
                  id="standardIndexId"
                  label="Index ID"
                  className={classes.textField}
                  value={this.state.indexId}
                  style={textFieldStyle}
                  onChange={this.handleChange('indexId')}
                  margin="normal"
                />
                <TextField
                  id="standardBookTitle"
                  label="Book Title"
                  className={classes.textField}
                  value={this.state.title}
                  style={textFieldStyle}
                  onChange={this.handleChange('title')}
                  margin="normal"
                />
                <TextField
                  id="standardBookTitle2"
                  label="Book Title 2"
                  className={classes.textField}
                  value={this.state.title2}
                  style={textFieldStyle}
                  onChange={this.handleChange('title2')}
                  margin="normal"
                />
                <TextField
                  id="standardBookAuthor"
                  label="Book Author"
                  className={classes.textField}
                  value={this.state.author}
                  style={textFieldStyle}
                  onChange={this.handleChange('author')}
                  margin="normal"
                />
                <TextField
                  id="standardBookThumbnailUrl"
                  label="Book Thumbnail URL"
                  className={classes.textField}
                  value={this.state.thumbnailUrl}
                  style={textFieldStyle}
                  onChange={this.handleChange('thumbnailUrl')}
                  margin="normal"
                />
              </div>
              <div>
                <FormControlLabel
                  className="standardBookId"
                  label="Select Content type"
                  labelPlacement="start"
                  style={{ paddingRight: '120px' }}
                  control={(
                    <Select
                      value={this.state.contentType}
                      onChange={this.handleChange('contentType')}
                      variant="filled"
                      style={{
                        marginLeft: '24px'
                      }}
                    >
                      <MenuItem value="EPUB">EPUB</MenuItem>
                      <MenuItem value="SPDF">SPDF</MenuItem>
                      <MenuItem value="CITE">CITE</MenuItem>
                      <MenuItem value="PXE">PXE</MenuItem>
                      <MenuItem value="EPUB3">EPUB3</MenuItem>
                      <MenuItem value="PDF">PDF</MenuItem>
                    </Select>
                  )}
                />
                <FormControlLabel
                  className="standardClientApp"
                  label="Select ClientApp"
                  labelPlacement="start"
                  style={{ paddingRight: '120px' }}
                  control={(
                    <Select
                      value={this.state.clientApp}
                      onChange={this.handleChange('clientApp')}
                      variant="filled"
                      style={{
                        marginLeft: '24px'
                      }}
                    >
                      <MenuItem value="ISE">Default</MenuItem>
                      <MenuItem value="ETEXT1_WEB">PDF</MenuItem>
                      <MenuItem value="ETEXT2_WEB">COLLECTION</MenuItem>
                      <MenuItem value="READER_PLUS">Reader +</MenuItem>
                    </Select>
                  )}
                />
                <FormControlLabel
                  className="sampleAppDropdown"
                  label="Select Environment"
                  labelPlacement="start"
                  control={(
                    <Select
                      value={this.state.env}
                      onChange={this.handleChange('env')}
                      variant="filled"
                      style={{
                        marginLeft: '15px'
                      }}
                    >
                      <MenuItem value="stg" selected>STAGE</MenuItem>
                      <MenuItem value="local" selected>LOCAL</MenuItem>
                      <MenuItem value="dev">DEV</MenuItem>
                      <MenuItem value="qa">QA</MenuItem>
                      <MenuItem value="qaInt">QA-INT</MenuItem>
                      <MenuItem value="prod">PROD</MenuItem>
                      <MenuItem value="sinprod">SIN-PROD</MenuItem>
                      <MenuItem value="perf">PREF</MenuItem>
                      <MenuItem value="sinperf">SIN-PREF</MenuItem>
                    </Select>
                  )}
                />
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Config details</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <div>
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.authApigee}
                      onChange={this.handleChangeToggle('authApigee')}
                      value={this.state.authApigee}
                      color="primary"
                    />
                  )}
                  label="authApigee"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.enableQuickView}
                      onChange={this.handleChangeToggle('enableQuickView')}
                      value={this.state.enableQuickView}
                      color="primary"
                    />
                  )}
                  label="QuickView"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.isDoublePage}
                      onChange={this.handleChangeToggle('isDoublePage')}
                      value={this.state.isDoublePage}
                      color="primary"
                    />
                  )}
                  label="Double page"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.isRightToLeft}
                      onChange={this.handleChangeToggle('isRightToLeft')}
                      value={this.state.isRightToLeft}
                      color="primary"
                    />
                  )}
                  label="isRightToLeft"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.isFixedLayout}
                      onChange={this.handleChangeToggle('isFixedLayout')}
                      value={this.state.isFixedLayout}
                      color="primary"
                    />
                  )}
                  label="Fixed Layout"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.rightStart}
                      onChange={this.handleChangeToggle('rightStart')}
                      value={this.state.rightStart}
                      color="primary"
                    />
                  )}
                  label="isRightStart"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.isStudent}
                      onChange={this.handleChangeToggle('isStudent')}
                      value={this.state.isStudent}
                      color="primary"
                    />
                  )}
                  label="Student"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.mathmlOn}
                      onChange={this.handleChangeToggle('mathmlOn')}
                      value={this.state.mathmlOn}
                      color="primary"
                    />
                  )}
                  label="mathmlOn"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.showAudio}
                      onChange={this.handleChangeToggle('showAudio')}
                      value={this.state.showAudio}
                      color="primary"
                    />
                  )}
                  label="showAudio"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.showAudioBeta}
                      onChange={this.handleChangeToggle('showAudioBeta')}
                      value={this.state.showAudioBeta}
                      color="primary"
                    />
                  )}
                  label="showAudioBeta"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.showBackNav}
                      onChange={this.handleChangeToggle('showBackNav')}
                      value={this.state.showBackNav}
                      color="primary"
                    />
                  )}
                  label="showBackNav"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.showBookmark}
                      onChange={this.handleChangeToggle('showBookmark')}
                      value={this.state.showBookmark}
                      color="primary"
                    />
                  )}
                  label="showBookmark"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.showCopy}
                      onChange={this.handleChangeToggle('showCopy')}
                      value={this.state.showCopy}
                      color="primary"
                    />
                  )}
                  label="showCopy"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.showDrawer}
                      onChange={this.handleChangeToggle('showDrawer')}
                      value={this.state.showDrawer}
                      color="primary"
                    />
                  )}
                  label="showDrawer"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.showDropMenu}
                      onChange={this.handleChangeToggle('showDropMenu')}
                      value={this.state.showDropMenu}
                      color="primary"
                    />
                  )}
                  label="showDropMenu"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.showHeader}
                      onChange={this.handleChangeToggle('showHeader')}
                      value={this.state.showHeader}
                      color="primary"
                    />
                  )}
                  label="showHeader"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.showHighlights}
                      onChange={this.handleChangeToggle('showHighlights')}
                      value={this.state.showHighlights}
                      color="primary"
                    />
                  )}
                  label="showHighlights"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.showLastVisitedPage}
                      onChange={this.handleChangeToggle('showLastVisitedPage')}
                      value={this.state.showLastVisitedPage}
                      color="primary"
                    />
                  )}
                  label="showLastVisitedPage"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.showNavigation}
                      onChange={this.handleChangeToggle('showNavigation')}
                      value={this.state.showNavigation}
                      color="primary"
                    />
                  )}
                  label="showNavigation"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.showPageLayout}
                      onChange={this.handleChangeToggle('showPageLayout')}
                      value={this.state.showPageLayout}
                      color="primary"
                    />
                  )}
                  label="showPageLayout"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.showPref}
                      onChange={this.handleChangeToggle('showPref')}
                      value={this.state.showPref}
                      color="primary"
                    />
                  )}
                  label="showPref"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.showPrintPageNav}
                      onChange={this.handleChangeToggle('showPrintPageNav')}
                      value={this.state.showPrintPageNav}
                      color="primary"
                    />
                  )}
                  label="showPrintPageNav"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.showResource}
                      onChange={this.handleChangeToggle('showResource')}
                      value={this.state.showResource}
                      color="primary"
                    />
                  )}
                  label="showResource"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.showSearch}
                      onChange={this.handleChangeToggle('showSearch')}
                      value={this.state.showSearch}
                      color="primary"
                    />
                  )}
                  label="showSearch"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.allowNotesCopy}
                      onChange={this.handleChangeToggle('allowNotesCopy')}
                      value={this.state.allowNotesCopy}
                      color="primary"
                    />
                  )}
                  label="Allow Notes Copy"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.allowTocEdit}
                      onChange={this.handleChangeToggle('allowTocEdit')}
                      value={this.state.allowTocEdit}
                      color="primary"
                    />
                  )}
                  label="Allow Toc Edit"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.showShareNote}
                      onChange={this.handleChangeToggle('showShareNote')}
                      value={this.state.showShareNote}
                      color="primary"
                    />
                  )}
                  label="showShareNote"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.showSocialComponent}
                      onChange={this.handleChangeToggle('showSocialComponent')}
                      value={this.state.showSocialComponent}
                      color="primary"
                    />
                  )}
                  label="Show Social Component"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.showStudy}
                      onChange={this.handleChangeToggle('showStudy')}
                      value={this.state.showStudy}
                      color="primary"
                    />
                  )}
                  label="Show Study Tools"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.showStudyPanelChannelsWidget}
                      onChange={this.handleChangeToggle('showStudyPanelChannelsWidget')}
                      value={this.state.showStudyPanelChannelsWidget}
                      color="primary"
                    />
                  )}
                  label="Show Study Panel Channels Widget"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.showFlashcardViewInContent}
                      onChange={this.handleChangeToggle('showFlashcardViewInContent')}
                      value={this.state.showFlashcardViewInContent}
                      color="primary"
                    />
                  )}
                  label="Show Flashcard ViewInContent Option"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.showStudyChannelNewVariation}
                      onChange={this.handleChangeToggle('showStudyChannelNewVariation')}
                      value={this.state.showStudyChannelNewVariation}
                      color="primary"
                    />
                  )}
                  label="show StudyChannel NewVariation Option"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.showToc}
                      onChange={this.handleChangeToggle('showToc')}
                      value={this.state.showToc}
                      color="primary"
                    />
                  )}
                  label="Show TOC"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.showFoc}
                      onChange={this.handleChangeToggle('showFoc')}
                      value={this.state.showFoc}
                      color="primary"
                    />
                  )}
                  label="Show Foc Tools"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.isSpdfContent}
                      onChange={this.handleChangeToggle('isSpdfContent')}
                      value={this.state.isSpdfContent}
                      color="primary"
                    />
                  )}
                  label="Is SPDF Content"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.showAudioReadAlong}
                      onChange={this.handleChangeToggle('showAudioReadAlong')}
                      value={this.state.showAudioReadAlong}
                      color="primary"
                    />
                  )}
                  label="Show Audio Read Along"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.showFontSlider}
                      onChange={this.handleChangeToggle('showFontSlider')}
                      value={this.state.showFontSlider}
                      color="primary"
                    />
                  )}
                  label="Show Font Slider"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.enableChannelInSearch}
                      onChange={this.handleChangeToggle(
                        'enableChannelInSearch'
                      )}
                      value={this.state.enableChannelInSearch}
                      color="primary"
                    />
)}
                  label="Enable Channels In Search"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.showPDFChapterFilter}
                      onChange={this.handleChangeToggle('showPDFChapterFilter')}
                      value={this.state.showPDFChapterFilter}
                      color="primary"
                    />
)}
                  label="Show Inchapters in Search"
                />
                <FormControlLabel
                  className="skin"
                  label="Select Skin"
                  labelPlacement="start"
                  control={(
                    <Select
                      value={this.state.skin}
                      onChange={this.handleChange('skin')}
                      variant="filled"
                      style={{
                        marginLeft: '15px'
                      }}
                    >
                      <MenuItem value="" />
                      <MenuItem value="YL">YL</MenuItem>
                    </Select>
                  )}
                />
                <Typography className={classes.heading}> TOC Data </Typography>
                <textarea
                  rows="7"
                  cols="100"
                  value={(this.state.tocData)}
                  onChange={this.handleChange('tocData')}
                />
                <Typography className={classes.heading}> PDF Page Playlist </Typography>
                <textarea
                  rows="7"
                  cols="100"
                  value={(this.state.pagePlaylist)}
                  onChange={this.handleChange('pagePlaylist')}
                />
                <Typography className={classes.heading}> PDF Hotspot Data </Typography>
                <textarea
                  rows="7"
                  cols="100"
                  value={(this.state.hotspotData)}
                  onChange={this.handleChange('hotspotData')}
                />
                <TextField
                  id="standardPageId"
                  label="Page ID"
                  className={classes.textField}
                  value={this.state.pageId}
                  style={textFieldStyle}
                  onChange={this.handleChange('pageId')}
                  margin="normal"
                />
                <TextField
                  id="locale"
                  label="Locale"
                  className={classes.textField}
                  value={this.state.locale}
                  style={textFieldStyle}
                  onChange={this.handleChange('locale')}
                  margin="normal"
                />
                <TextField
                  id="height"
                  label="Book Height"
                  type="number"
                  className={classes.textField}
                  value={this.state.height}
                  style={textFieldStyle}
                  onChange={this.handleChange('height')}
                  margin="normal"
                />
                <TextField
                  id="width"
                  label="Book Width"
                  type="number"
                  className={classes.textField}
                  value={this.state.width}
                  style={textFieldStyle}
                  onChange={this.handleChange('width')}
                  margin="normal"
                />
                <TextField
                  id="businessModelCode"
                  label="Business Model Code"
                  className={classes.textField}
                  value={this.state.businessModelCode}
                  style={textFieldStyle}
                  onChange={this.handleChange('businessModelCode')}
                  margin="normal"
                />
                <TextField
                  id="printPageNumber"
                  label="Print Page Number"
                  className={classes.textField}
                  value={this.state.printPageNumber}
                  style={textFieldStyle}
                  onChange={this.handleChange('printPageNumber')}
                  margin="normal"
                />
                <TextField
                  id="versionUrn"
                  label="versionUrn"
                  className={classes.textField}
                  value={this.state.versionUrn}
                  style={textFieldStyle}
                  onChange={this.handleChange('versionUrn')}
                  margin="normal"
                />
                <FormControlLabel
                  className="sampleAppDropdown"
                  label="Master Theme"
                  labelPlacement="start"
                  style={{ paddingRight: '120px' }}
                  control={(
                    <Select
                      value={this.state.masterTheme}
                      onChange={this.handleChange('masterTheme')}
                      variant="filled"
                      style={{
                        marginLeft: '15px'
                      }}
                    >
                      <MenuItem value="standard">Standard</MenuItem>
                      <MenuItem value="plus" selected>Plus (Mojo)</MenuItem>
                    </Select>
                  )}
                />
                <FormControlLabel
                  className="sampleAppDropdown"
                  label="Theme"
                  labelPlacement="start"
                  control={(
                    <Select
                      value={this.state.theme}
                      onChange={this.handleChange('theme')}
                      variant="filled"
                      style={{
                        marginLeft: '15px'
                      }}
                    >
                      <MenuItem value="White" selected>White</MenuItem>
                      <MenuItem value="Akaroa">Akaroa</MenuItem>
                      <MenuItem value="Black">Black</MenuItem>
                    </Select>
                  )}
                />
              </div>
            </AccordionDetails>
          </Accordion>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={this.handleSubmit}
            className={classes.margin}
          >
            Open Book
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={this.handleReset}
            className={classes.margin}
          >
            Reset Form
          </Button>
        </Paper>
      </div>
    );
  }
}

DetailsForm.propTypes = {
  classes: PropTypes.object.isRequired,
  /**
   * The Book, auth, userdetails and config information
   */
  setDetails: PropTypes.func.isRequired
};

export default withStyles(styles)(DetailsForm);
