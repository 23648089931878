/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * VegaReader wrapper container
 *
 * @file VegaReaderContainer.js
 * @author Srinivasan Bakthavatchalam
 * @since 2.0.0
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { VegaReader } from '@vega/vega-reader';
import { ResourceContext } from '@vega/resources';

import appConfig from '../config/appConfig';
import AudioModal from '../asset/components/AudioModal';
// Sample Icon components import
import CalendarSample from '../asset/components/CalendarSample';
import DialogSample from '../asset/components/DialogSample';
// import MessageSample from '../asset/components/MessageSample'; TODO - Use when menuIconsEnd is enabled

import {
  BOOK_FORMAT_EPUB,
  BOOK_FORMAT_SPDF,
} from '../common/constants'

class VegaReaderContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentPageIdArray: [],
      openDialog: false,
      pageId: props.pageId,
      showFontSlider: props.showFontSlider
    };
    this.channelId = 'biology';
    // set current page id for resource
    this.setCurrentPageId();
    // set double page layout value
    // TODO: To be removed during preference API integration
    this.setIsDoublePage();
  }

  getChildContext() {
    const {
      showStudyPanelChannelsWidget,
      showFlashcardViewInContent,
      showStudyChannelNewVariation
    } = this.props;
    return {
      preferenceContext: {
        showColorMode: true,
        showPagelayout: true,
        showPageProgression: true,
        showAnnotationSwitch: true,
        showFontOptions: true,
        showTextTransform: true,
        showLineSpacing: true,
        showHotSpots: true,
        showFontSlider: this.state.showFontSlider
      },
      studyContext: {
        glossaryLanguage: 'en-us',
        showCustomDecks: true,
        showExpertDecks: true,
        showKeyTerms: true,
        showAIDecks: true,
        studyLDFlagsContext: {
          showStudyPanelChannelsWidget: showStudyPanelChannelsWidget,
          showFlashcardViewInContent: showFlashcardViewInContent,
          showStudyChannelNewVariation: showStudyChannelNewVariation,
          showAITutor: true,
          showAIExpertDecks: true,
          isNewFlashcardUIEnable: true
        }
      }
    };
  }

  authHandler = () => ({
    getToken: () => {
      const tokenConfig = {
        id: '',
        token: this.props.token
      }

      return new Promise((resolve) => {
        resolve(tokenConfig);
      });
    },
    getTokenConfig: () => {
      const tokenConfig = {
        callApigee: this.props.authApigee,
        id: '',
        type: this.props.type,
        userRole: this.props.userRole
      };

      return tokenConfig;
    },
    getUserId: () => this.props.userId,
    onError: () => { },
    getCdnToken: () => ({
      name: appConfig.authCdnTokenName,
      value: appConfig.authCdnTokenValue
    }),
    getHawkConfig: () => ({
      hawkId: this.props.hawkId,
      hawkKey: this.props.hawkKey
    })
  })

  /**
   * Set double page layout for the book if already stored
   * else will set the value selected in the book details form
   *
   * TODO: To be removed during preference API integration
   *
   */
  setIsDoublePage = () => {
    const { bookId, isDoublePage } = this.props;
    const bookDoublePage = JSON.parse(localStorage.getItem(`${bookId}_isDoublePage`));

    this.isDoublePage = typeof bookDoublePage === 'boolean' ? bookDoublePage : isDoublePage;
  }

  /**
   * Handle back navigation click
   *
   */
  handleBackNavigationClick = () => {
    this.setState({ openDialog: true });
  }

  /**
   * Handle sample dialog close
   *
   */
  handleDialogClose = () => {
    this.setState({ openDialog: false });
  }

  /**
   * Handle page navigation
   *
   * @param {string} pageId
   */
  handleNavigationChange = (pageId) => {
    this.setState({ pageId });
    this.setCurrentPageId(pageId, true);
  }

  /**
   * Handle Notes copy
   * This will be triggered on click of the 'Copy notes' link
   * in the Notebook tab of the left panel drawer
   *
   */
  handleNotesCopy = () => {
    this.setState({ openDialog: true });
  }

  /**
   * Handle TOC edit
   * This will be triggered on click of the 'customize' link
   * in the Content tab of the left panel drawer
   *
   */
  handleTocEdit = () => {
    this.setState({ openDialog: true });
  }

  /**
   * Prepare Menu Item
   * This method is used to prepare the menu items
   * for More Menu Data
   *
   */
  prepareMenuItem = () => {
    const username = {
      type: 'nonlink',
      text: 'Welcome, LastName Firstname',
      value: ''
    };
    const support = {
      type: 'link',
      text: 'Customer Support',
      value: 'support'
    };
    const divider = {
      type: 'divider',
      text: '',
      value: ''
    };
    const about = {
      type: 'expand',
      text: 'About',
      value: '',
      list: [
        {
          type: 'link',
          text: 'Accessibility',
          value: 'accessibility'
        },
        {
          type: 'link',
          text: 'Terms of Use',
          value: 'tos'
        },
        {
          type: 'link',
          text: 'Privacy Policy',
          value: 'privacy'
        },
        {
          type: 'nonlink',
          text: 'Standalone eText, PXE',
          value: ''
        },
        {
          type: 'copyright',
          text: '',
          value: ''
        }
      ]
    };
    const signout = {
      type: 'signout',
      text: 'Signout',
      value: 'signout'
    };
    const printoption = {
      type: 'link',
      text: 'Print page',
      value: 'print'
    };
    const menuItem = [
      username,
      support,
      divider,
      about,
      divider,
      signout
    ];

    if (Math.random() >= 0.4) {
      menuItem.splice(1, 0, printoption);
    }

    return menuItem;
  };

  /**
   * Get the playlist for double page epub books start from right
   *
   * @param {Array} playlistData
   * @param {Boolean} isDoublepage
   */
  getPlaylistRightStart = (playlistData, isDoublepage) => {
    const updatedPlaylist = [];

    for (let i = 0; i < playlistData.length; i += 1) {
      if (isDoublepage) {
        updatedPlaylist.push(playlistData[i]);
        // adding a blank page in the case of double page with right start
        // TODO: check whether we can get a blank page from the service
        if (i === 0) {
          updatedPlaylist.push(playlistData[0]);
        }
      } else if (i === 0) {
        // eslint-disable-next-line no-unused-expressions
        playlistData[0].id !== playlistData[1].id && updatedPlaylist.push(playlistData[i]);
      } else {
        updatedPlaylist.push(playlistData[i]);
      }
    }

    return updatedPlaylist;
  }

  /**
   * Get current page for page number passed
   *
   * @param {*} pagenumber
   * @param {Boolean} isAudio
   * @param {Object} audioObject
   */
  getPageDetails = (pagenumber, isAudio, audioObject) => {
    const { rightStart } = this.props;
    const currentPageNumber = pagenumber;
    let { pagePlaylist } = this.props;
    let currentPage = {};

    pagePlaylist = JSON.parse(pagePlaylist);
    const preferences = JSON.parse(localStorage.getItem('vegareader.preference'));

    if (pagePlaylist.length > 0) {
      if (rightStart) {
        pagePlaylist = this.getPlaylistRightStart(pagePlaylist, preferences.isDoublePage);
      }
      const currentPageIndex = pagePlaylist.findIndex((eachPage) => eachPage.pagenumber === currentPageNumber);

      if (preferences.isDoublePage) {
        currentPage = currentPageIndex % 2 === 0 ? pagePlaylist[currentPageIndex] : pagePlaylist[currentPageIndex - 1];
      } else {
        currentPage = pagePlaylist[currentPageIndex];
      }
      if (!currentPage) {
        currentPage = pagePlaylist[0];
      }
    }

    this.handleNavigationChange(currentPage.id);
    if (isAudio) {
      this.setState({ isAudioOpen: isAudio, audioObject });
    }
  };

  /**
   * Handles audio popover close
   *
   */
  handleAudioPopoverClose = () => {
    this.setState({ isAudioOpen: false });
  }

  /**
   * Handle Change
   * This is a callback method for more menu items
   * which is used for handling clicks from header more menu
   *
   * @param {*} event
   * @param {Object} item
   */
  handleChange = (event, item) => {
    switch (item.value) {
      case 'support':
        this.redirectToOtherDomain(
          // eslint-disable-next-line max-len
          'https://support.pearson.com/getsupport/s/?_ga=2.116082310.1845539610.1557867919-NimtqV7BcT00BM9xOXCQUFJYwF3RzEIk'
        );
        break;
      case 'accessibility':
        this.redirectToOtherDomain('https://www.pearson.com/us/accessibility.html');
        break;
      case 'tos':
        this.redirectToOtherDomain('https://pi.pearsoned.com/v1/piapi/policies/static/html/PearsonEULA.html');
        break;
      case 'privacy':
        this.redirectToOtherDomain('https://pi.pearsoned.com/v1/piapi/policies/static/html/PearsonPrivacyPolicy.html');
        break;
      default:
        break;
    }
  };

  /**
   * PDF Callbacks
   * This callback method is used to send hotspot details
   * for every PDF page loaded.
   */
  pdfCallbacks = () => {
    const hotspotData = JSON.parse(this.props.hotspotData);

    const { hotspotList } = hotspotData.regions[0];

    return {
      hotspotObj: {
        bookFeatures: {
          hotspotcolor: '#c10a5d',
          isunderlinehotspot: false,
          regionhotspotalpha: '50',
          underlinehotspotcolor: '#0000FF',
          underlinehotspotthickness: '2'
        },
        fetchClickedRegionData: () => {},
        fetchHotspotData: () => new Promise((resolve) => {
          resolve(hotspotList);
        }),
        renderGlossaryData: () => {}
      }
    };
  }

  /**
   * Redirect to Other Domain
   * This is a common method to redirect page to other domains
   * when a link is clicked from more menu
   *
   * @param {string} url
   */
  redirectToOtherDomain = (url) => {
    window.open(url, '_blank');
  };

  /**
   * Set current page id in an array
   *
   * @param {*} pageId
   * @param {*} pageChanged
   */
   setCurrentPageId = (pageId, pageChanged) => {
    let currentPageId = pageId;
    let isDoublePage = this.isDoublePageBook;

    if ((this.contentType === BOOK_FORMAT_EPUB
      || this.contentType === BOOK_FORMAT_SPDF)
      && this.pagePlaylist
      && this.pagePlaylist.length > 0) {
      if (!currentPageId) {
        currentPageId = this.pagePlaylist[0].id;
      }
      const currentPageIndex = this.pagePlaylist.findIndex((eachPage) => eachPage.id === currentPageId);
      const currentPageNumberArray = [];
      let currentPage = this.pagePlaylist[currentPageIndex];

      currentPageNumberArray.push(currentPage.pagenumber);
      if (pageChanged
        && localStorage.getItem('vegareader.preference')) {
        isDoublePage = JSON.parse(localStorage.getItem('vegareader.preference')).isDoublePage;
      }
      let rightStart = false;

      if (this.layoutDimensions && this.layoutDimensions.rightStart) {
        rightStart = this.layoutDimensions.rightStart;
      }
      if (isDoublePage
        && ((currentPageIndex > 0
        && rightStart) || !rightStart)) {
        currentPage = this.pagePlaylist[currentPageIndex + 1];
        if (currentPage) currentPageNumberArray.push(currentPage.pagenumber);
      }
      this.setState({ currentPageIdArray: currentPageNumberArray });
    }
  }

  render() {
    const {
      allowNotesCopy,
      allowTocEdit,
      applicationId,
      authApigee,
      author,
      basePath,
      bookId,
      businessModelCode,
      clientApp,
      contentType,
      courseId,
      enableQuickView,
      env,
      height,
      isFixedLayout,
      isRightToLeft,
      isSpdfContent,
      isStudent,
      indexId,
      legacyBookId,
      locale,
      masterTheme,
      mathmlOn,
      pagePlaylist,
      rightStart,
      showAudio,
      showAudioBeta,
      showBackNav,
      showBookmark,
      showCopy,
      showDrawer,
      showDropMenu,
      showFoc,
      showHeader,
      showHighlights,
      showLastVisitedPage,
      showNavigation,
      showPageLayout,
      showPref,
      showPrintPageNav,
      showResource,
      showSearch,
      showShareNote,
      showSocialComponent,
      showStudy,
      showStudyPanelChannelsWidget,
      showStudyChannelNewVariation,
      showToc,
      skin,
      tenantId,
      tenantKey,
      theme,
      thumbnailUrl,
      title,
      title2,
      tocData,
      versionUrn,
      width,
      secondaryBookId,
      showAudioReadAlong,
      showPDFChapterFilter,
      enableChannelInSearch
    } = this.props;
    const layoutDimensions = {
      height,
      width,
      rightStart
    };
    const preferenceObject = {
      sliderVal: contentType === 'EPUB' ? '100' : '0',
      theme
    };

    let appId = applicationId || 'ise';
    if (clientApp === 'ETEXT1_WEB') {
      appId = 'etext1'
    } else if (clientApp === 'READER_PLUS') {
      appId = 'readerPlus';
    }

    return (
      <div>
        <ResourceContext
          bookId={bookId}
          currentPageId={this.state.currentPageIdArray}
          env={env}
          goToPageCallback={this.getPageDetails}
          locale={locale}
          token=""
          userRole={isStudent ? 'Student' : 'Teacher'}
        >
          <VegaReader
            authHandler={this.authHandler()}
            basePath={basePath}
            bookId={bookId}
            secondaryBookId={secondaryBookId}
            bookInfo={{
              author,
              isDoublePage: this.isDoublePage, // TODO: To be updated during preference API integration
              isFixedLayout,
              isRightToLeft,
              layoutDimensions,
              legacyBookId,
              preferenceObject,
              thumbnailUrl,
              title,
              title2,
              channelId: this.channelId
            }}
            businessModelCode={businessModelCode}
            config={{
              allowNotesCopy,
              allowTocEdit,
              applicationId: appId,
              authApigee,
              clientApp,
              enableQuickView,
              isSpdfContent,
              isStudent,
              locale,
              mathmlOn,
              showAudio,
              showAudioBeta,
              showBackNav,
              showBookmark,
              showCopy,
              showDrawer,
              showDropMenu,
              showFoc,
              showHeader,
              showHighlights,
              showLastVisitedPage,
              showNavigation,
              showPageLayout,
              showPref,
              showPrintPageNav,
              showResource,
              showSearch,
              showShareNote,
              showSocialComponent,
              showStudy,
              showStudyPanelChannelsWidget,
              showStudyChannelNewVariation,
              showToc,
              skin,
              tenantId,
              tenantKey,
              showAudioReadAlong,
               enableChannelInSearch,
               showPDFChapterFilter
            }}
            contentType={contentType}
            courseId={courseId}
            env={env}
            indexId={indexId}
            theme={masterTheme}
            /* TODO - Enable after new Header Component is published after rearranging the icons
            menuIconsEnd={(
              <>
                <MessageSample />
                <CalendarSample />
              </>
            )} */
            menuIconsStart={(<CalendarSample />)}
            moreMenuData={{
              handleChange: this.handleChange,
              menuItem: this.prepareMenuItem()
            }}
            onBackNavClick={this.handleBackNavigationClick}
            onNavigationChange={this.handleNavigationChange}
            onNotesCopy={this.handleNotesCopy} // handling onNotesCopy inside the AppContainer(or parent application)
            onTocEdit={this.handleTocEdit} // handling onTocEdit inside the AppContainer(or parent application)
            pageId={this.state.pageId}
            pagePlaylist={JSON.parse(pagePlaylist)} // Needs to be sent for Etext pdf
            pdfCallbacks={this.pdfCallbacks()}
            tocData={JSON.parse(tocData)}
            versionUrn={versionUrn}
          />
        </ResourceContext>
        <AudioModal
          isAudioModalShown={this.state.isAudioOpen}
          audioObject={this.state.audioObject}
          onClose={this.handleAudioPopoverClose}
        />
        <DialogSample
          open={this.state.openDialog}
          onClose={this.handleDialogClose}
        />
      </div>
    );
  }
}

VegaReaderContainer.propTypes = {
  allowNotesCopy: PropTypes.bool,
  allowTocEdit: PropTypes.bool,
  applicationId: PropTypes.string,
  authApigee: PropTypes.bool,
  basePath: PropTypes.string,
  bookId: PropTypes.string,
  secondaryBookId: PropTypes.string,
  businessModelCode: PropTypes.string,
  clientApp: PropTypes.string,
  contentType: PropTypes.string,
  courseId: PropTypes.string,
  enableQuickView: PropTypes.bool,
  env: PropTypes.string,
  frToken: PropTypes.string,
  hawkId: PropTypes.string,
  hawkKey: PropTypes.string,
  height: PropTypes.number,
  hotspotData: PropTypes.string,
  isDoublePage: PropTypes.bool,
  isFixedLayout: PropTypes.bool,
  isRightToLeft: PropTypes.bool,
  isStudent: PropTypes.bool,
  indexId: PropTypes.string,
  legacyBookId: PropTypes.string,
  locale: PropTypes.string,
  masterTheme: PropTypes.string,
  mathmlOn: PropTypes.bool,
  pageId: PropTypes.string,
  pagePlaylist: PropTypes.string,
  rightStart: PropTypes.bool,
  showAudio: PropTypes.bool,
  showAudioBeta: PropTypes.bool,
  showBackNav: PropTypes.bool,
  showBookmark: PropTypes.bool,
  showCopy: PropTypes.bool,
  showDrawer: PropTypes.bool,
  showDropMenu: PropTypes.bool,
  showFoc: PropTypes.bool,
  showFontSlider: PropTypes.bool,
  showHeader: PropTypes.bool,
  showLastVisitedPage: PropTypes.bool,
  showNavigation: PropTypes.bool,
  showPageLayout: PropTypes.bool,
  showPref: PropTypes.bool,
  showPrintPageNav: PropTypes.bool,
  showResource: PropTypes.bool,
  showSearch: PropTypes.bool,
  showShareNote: PropTypes.bool,
  showSocialComponent: PropTypes.bool,
  showAudioReadAlong: PropTypes.bool,
  showStudy: PropTypes.bool,
  showStudyPanelChannelsWidget: PropTypes.bool,
  showFlashcardViewInContent: PropTypes.bool,
  showStudyChannelNewVariation: PropTypes.bool,
  showToc: PropTypes.bool,
  enableChannelInSearch: PropTypes.bool,
  showPDFChapterFilter: PropTypes.bool,
  skin: PropTypes.string,
  tenantId: PropTypes.string,
  tenantKey: PropTypes.string,
  theme: PropTypes.string,
  tocData: PropTypes.object,
  token: PropTypes.string,
  type: PropTypes.string,
  userId: PropTypes.string,
  userRole: PropTypes.string,
  versionUrn: PropTypes.string,
  width: PropTypes.number
};

VegaReaderContainer.defaultProps = {
  allowNotesCopy: true,
  allowTocEdit: true,
  applicationId: 'ise',
  authApigee: false,
  basePath: '',
  bookId: '',
  secondaryBookId: '',
  businessModelCode: '',
  clientApp: 'ISE',
  contentType: '',
  courseId: '',
  enableQuickView: false,
  env: '',
  frToken: '',
  hawkId: '',
  hawkKey: '',
  height: 0,
  hotspotData: '{ "regions": [ { "hotspotList": [] } ] }',
  isFixedLayout: false,
  isDoublePage: false,
  isRightToLeft: false,
  isStudent: true,
  indexId: '',
  legacyBookId: '',
  locale: 'ar',
  masterTheme: 'plus',
  mathmlOn: false,
  pageId: '',
  pagePlaylist: '[]',
  rightStart: false,
  showAudio: false,
  showAudioBeta: false,
  showBackNav: true,
  showBookmark: true,
  showCopy: true,
  showDrawer: true,
  showDropMenu: false,
  showFoc: false,
  showFontSlider: true,
  showHeader: true,
  showLastVisitedPage: false,
  showNavigation: true,
  showPageLayout: false,
  showPref: true,
  showPrintPageNav: true,
  showResource: false,
  showSearch: true,
  showShareNote: false,
  showSocialComponent: false,
  showStudy: true,
  showAudioReadAlong: false,
  showStudyPanelChannelsWidget: false,
  showFlashcardViewInContent: true,
  showStudyChannelNewVariation: false,
  showToc: true,
  skin: '',
  tenantId: '',
  tenantKey: '',
  theme: 'White',
  tocData: {},
  token: '',
  type: '',
  userId: '',
  userRole: '',
  versionUrn: '',
  width: 0,
  enableChannelInSearch: true,
  showPDFChapterFilter: true
};

VegaReaderContainer.childContextTypes = {
  preferenceContext: PropTypes.object,
  studyContext: PropTypes.object
};

export default VegaReaderContainer;
